import logo from './logo.svg';
import './App.css';
import  Header from './Header';
import './css/home.css';
import HeadSpace from './HeadSpace';
import MainBanner from './MainBanner';
import About from './About';
import Work from './work';
import Certifications from './certifications';
import WhatIWrite from './whatiwrite';
import Contact from './contact';
import Footer from './footer';

function App() {
  return (
    <div>
    <Header/>
    <HeadSpace/>
    <MainBanner/>
    <About/>
    <Work/>
    <Certifications/>
    <WhatIWrite/>
    <Contact/>
    <Footer/>
    </div>


  );
}

export default App;
