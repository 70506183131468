import "./css/whatiwrite.css";

function Work() {
  return (
    <div className="container-fluid" id="whatiwrite">
      <div className="container overflow-hidden">
        <br />

        <section className="whatiwrite">What I Write</section>

        <br />
        <br />


        <div className="row gx-4 gy-5">
          <div className="col">

            <div className="card">
              <div className="card-body">
                <h5 className="card-title">My Personal Diary</h5>
                <br />
                <p className="card-text">Here, I write about my life experiences that I face on a regular basis. The posts here are towards my personal side of life.</p>
                <br />
                <a href="https://mpd.manishsingh.in" target="_blank" rel="noreferrer" class="btn btn-primary">Visit My Personal Diary</a>
              </div>
            </div>

          </div>


          <div className="col">

            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Thoughts</h5>
                <br />
                <p className="card-text">Lot of things keep on happening around us on a daily basis. In this section, I write my views on all such events.</p>
                <br />
                <a href="https://thoughts.manishsingh.in" target="_blank" rel="noreferrer" class="btn btn-primary">Visit Thoughts</a>
              </div>
            </div>

          </div>
</div>

        <br />
        <br />
        <br />












      </div>
    </div>


  );
}

export default Work;
