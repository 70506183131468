import "./css/footer.css";

function Footer() {

  const today = new Date();

  var year = today.getFullYear();

  return (
    <div className="container-fluid" id="footer">
      <div className="container overflow-hidden">
        <br/>
  
      <section className="footer">&copy; {year} Manish Singh</section>

      <br/>



      </div>
    </div>


  );
}

export default Footer;
