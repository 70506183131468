import "./css/contact.css";

function Contact() {



  return (
    <div className="container-fluid" id="Contact">
      <div className="container overflow-hidden">
        <br/>
  
      <section className="contact">Contact</section>

      <br/><br/>

      <div className="row">
    <div className="col-sm content_align">
      <br/>
      You can contact me by writing an email to &nbsp;<a href="mailto:manish@manishsingh.in?Subject=Hello!">manish@manishsingh.in</a>
    </div>
    <div className="col-sm">
    <div className="social-btn">
        <a href="https://www.facebook.com/manishsingh123" target="_blank"><div className="social face"><i className="fa fa-facebook fa-2x"></i><span>Facebook</span></div></a>
        <a href="https://twitter.com/manishsingh123" target="_blank"><div className="social twi"><i className="fa fa-twitter fa-2x"></i><span>Twitter</span></div></a>
        <a href="https://www.linkedin.com/in/msingh4/" target="_blank"><div className="social in"><i className="fa fa-linkedin  fa-2x"></i><span>Linked In</span></div></a>
        <a href="https://github.com/manish112" target="_blank"><div className="social git"><i className="fa fa-github fa-2x"></i><span>Github</span></div></a>

    </div>
    </div>
    </div>

      <br/>



      </div>
    </div>


  );
}

export default Contact;
