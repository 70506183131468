import './css/about.css'
import jiit from './img/jiit.png';
import bits from './img/bits_logo.png'

function About(){

    const today = new Date();
    
    var year_diff=today.getFullYear()-2008;

    return(
        <div className="container-fluid" id="About">
           <div className="container" id="about_inner">
            <br/>

            <section className="aboutme">About Me</section>

            <br/>

            <section className="aboutme_desc">

                <p>
                Hello, Thank you for visiting me on the web. I have been working in the software industry for {year_diff} years now. I have worked on multiple projects for Fortune 500 companies and have been delivering them successfully. My passion is to build new things and automate. I also like to explore new opportunities and work on new ideas. I have done my M. tech from BITS Pilani with a specialization in Data Analytics.

                </p>

                <br/>
                
            </section>
            <br/>

            <h4 class="aboutme_h4">Education</h4>
            <br/>

            <div className="row">


  <div className="col-sm-6">

  <div className="card mb-3" id="bits_box">
        <div className="row g-0">
    <div className="col-md-4 clgbox">
      
      <img src={bits}/>
      
    </div>
    <div className="col-md-8">
      <div className="card-body">
          
      <h5 className="card-title">M. Tech, BITS Pilani</h5>
        <p className="card-text">Software Systems - Data Analytics, 2019</p>
        <a href="https://www.bits-pilani.ac.in/" target="_blank" className="btn btn-primary">Visit BITS Pilani</a>

      </div>
    </div>
  </div>
</div>

   

  </div>
  <div className="col-sm-6">
  <div className="card mb-3" id="jiit_box">
        <div className="row g-0">
    <div className="col-md-4 clgbox">
      
      <img src={jiit}/>
      
    </div>
    <div className="col-md-8">
      <div className="card-body">
          
      <h5 className="card-title">B. Tech, JIIT Noida</h5>
        <p className="card-text">Computer Science and Engineering, 2007</p>
        <a href="http://www.jiit.ac.in" target="_blank" className="btn btn-primary">Visit JIIT Noida</a>

      </div>
    </div>
  </div>
</div>
  </div>
</div>

<br/>
<br/>
  
    
            </div>

        </div>

    );

}

export default About;