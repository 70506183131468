import './css/home.css';
import MainshImg from  './img/backgrnd.jpeg'

function MainBanner(){
return(
<div>
<div className="grid-container">

    <div className="manish_img">
       
        
        <img src={MainshImg} id="img_manish_pic"/>
        
    </div>

   
    <div className="manish_text">
<p id="ms_text">
  <div className="spinny-wrapper">
    <h1>I am a &nbsp;
    <span className="spinny-words">
      <span>Software Engineer</span>
      <span>Web Developer</span>
      <span>Teacher</span>
      <span>Leader</span>
      <span>AI and ML evangelist</span>
      
      <span>Proud father</span>
    </span>
    </h1>
  </div>
  </p>
  </div>

</div>
</div>
);
}

export default MainBanner;