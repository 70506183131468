import "./css/work.css";
import adobe from './img/adobe.png';
import infy from './img/infy.png';
import nagarro from './img/nagarro.png'

function WhatIWrite() {
  return (
    <div className="container-fluid" id="Work">
      <div className="container overflow-hidden">
        <br />

        <section className="work">Work</section>

        <br />

        <h4>Skill Sets</h4>
        <br />
        <h5>Technical</h5>

        <br />

        <div className="row gx-4 gy-5">
          <div className="col">
            <div className="card">
              <div className="card-header">Full Stack</div>
              <div className="card-body">
                <h5 className="card-title">Front End</h5>
                <p className="card-text">Javascript, CSS3, HTML5, React, HTL</p>

                <br />

                <h5 className="card-title">Backend</h5>
                <p className="card-text">
                  Java, C++, Python, PHP, Spring, Hibernate
                </p>

                <br />

                <h5 className="card-title">Database</h5>
                <p className="card-text">Oracle, MySQL, MongoDB</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-header">Tools and Frameworks</div>
              <div className="card-body">
                <h5 className="card-title">Content Management Systems</h5>
                <p className="card-text">
                  Adobe Experience Manager (AEM), Wordpress
                </p>

                <br />

                <h5 className="card-title">Data Analytics, AI and ML</h5>
                <p className="card-text">Numpy, Pandas, Tensorflow</p>

                <br />

                <h5 className="card-title">Creative Side</h5>
                <p className="card-text">Premier Pro, Photoshop, Audition</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-header">Environments</div>
              <div className="card-body">
                <h5 className="card-title">Mobile Development</h5>
                <p className="card-text">Android </p>

                <br />

                <h5 className="card-title">Cloud</h5>
                <p className="card-text">Docker, AWS</p>
                <br />

                <h5 className="card-title">Operating Systems</h5>
                <p className="card-text">Linux (CentOS), Mac, Windows</p>
              </div>
            </div>
          </div>
        </div>
        <br/>

        <div className="row gy-5">
            <div className="col gy-5">

            <div className="card">
              <div className="card-header">Others</div>
              <div className="card-body">
                <h5 className="card-title">Web Sever</h5>
                <p className="card-text">Apache, IIS, AEM Dispatcher </p>
                <br/>

                <h5 className="card-title">Microservices</h5>
                <p className="card-text">Spring Boot </p>

                <br />

                <h5 className="card-title">Data Structures and Algorithms</h5>
                
              </div>
            </div>



            </div>


        </div>

        <br />
        <br />
        <br />

        <h5>Managerial</h5>

        <br />

        <div className="row gx-4">
          <div className="col">
            <div className="card">
              <div className="card-header">Skills</div>
              <div className="card-body">
                <ul>
                  <li>
                    <p className="card-text">Team Handling</p>
                  </li>
                  <li>
                    <p className="card-text">Conflict Resolution</p>
                  </li>

                  <li>
                    <p className="card-text">Account Management</p>
                  </li>

                  <li>
                    <p className="card-text">
                      Worked with fortune 500 customers from APAC, EMEA and NA
                      region
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-header">Frameworks</div>
              <div className="card-body">
                <ul>
                  <li>
                    <p className="card-text">Waterfall</p>
                  </li>
                  <li>
                    <p className="card-text">Agile</p>
                  </li>
                  <li>
                    <p className="card-text">Prince2</p>
                  </li>
                  <li>
                    <p className="card-text">CSM</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
     

      <br/>
      <br/>
      <br/>

      <h4 class="aboutme_h4">Employment history</h4>
        <br />

        <div className="row">

        <div className="col-sm-6">
            <div className="card mb-3" id="jiit_box">
              <div className="row g-0">
                <div className="col-md-4 clgbox">

                  <img src={nagarro} />

                </div>
                <div className="col-md-8">
                  <div className="card-body">

                    <h5 className="card-title">Nagarro</h5>
                    <p className="card-text">Associate Principle Engineer</p>
                    <p className="card-text">Feb 2022 - Present</p>
                    <a href="http://www.nagarro.com" target="_blank" className="btn btn-primary">Visit Nagarro</a>

                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="col-sm-6">

            <div className="card mb-3" id="bits_box">
              <div className="row g-0">
                <div className="col-md-4 clgbox">

                  <img src={adobe} />

                </div>
                <div className="col-md-8">
                  <div className="card-body">

                    <h5 className="card-title">Adobe India</h5>
                    <p className="card-text">Lead Product Consultant - AEM</p>
                    <p className="card-text">Aug 2015 - Feb 2022</p>
                    <a href="https://www.adobe.com/" target="_blank" className="btn btn-primary">Visit Adobe</a>

                  </div>
                </div>
              </div>
            </div>



          </div>
          <div className="col-sm-6">
            <div className="card mb-3" id="jiit_box">
              <div className="row g-0">
                <div className="col-md-4 clgbox">

                  <img src={infy} />

                </div>
                <div className="col-md-8">
                  <div className="card-body">

                    <h5 className="card-title">Infosys Ltd - India and USA</h5>
                    <p className="card-text">Senior Systems Engineer and Technical Analyst</p>
                    <p className="card-text">Aug 2007 - July 2015</p>
                    <a href="http://www.infosys.com" target="_blank" className="btn btn-primary">Visit Infosys</a>

                  </div>
                </div>
              </div>
            </div>
          </div>

          
        </div>

        

        <br/>
        <br/>
        <br/>



        </div>
    </div>
  );
}

export default WhatIWrite;
