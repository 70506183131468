
import './css/nav.css';
function Header(){
   return(

       <header className="header">
<nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top">
<div className="container">
  <a className="navbar-brand" href="#">Manish Singh</a>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" id="nav_resp">
    <span className="navbar-toggler-icon"></span>
  </button>
 
  <div className="collapse navbar-collapse" id="navbarNav">
    <ul className="navbar-nav ml-auto">
      <li className="nav-item">
        <a className="nav-link" href="#About">About Me <span className="sr-only">(current)</span></a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#Work">Work</a>
      </li>
      <li className="nav-item">
        <a className="nav-link " href="#Certifications">Certifications</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#whatiwrite">What I write</a>
      </li>
      <li className="nav-item">
        <a className="nav-link " href="#Contact">Contact</a>
      </li>
    </ul>
  </div>
  </div>
</nav>
</header>



   );
}

export default Header;