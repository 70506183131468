import "./css/certifications.css";


function Certifications() {
  return (
    <div className="container-fluid" id="Certifications">
      <div className="container overflow-hidden">
        <br />

        <section className="certifications">Certifications</section>

        <br />
        <br/>
        <br/>

        <div className="row gx-4 gy-5">



    <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Deep Learning Specialization</h5>
        <p class="card-text">June 2020, License number: SNGM6H9FJPR2, Coursera</p>
        <a href="https://coursera.org/share/ad9f91cd7b879f764ab6e0a4e702db54" class="btn btn-primary" target="_blank">View Certificate</a>
       
      </div>
    </div>
  </div>


  
  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">IBM Data Science Professional Certificate</h5>
        <p class="card-text">May 2020, License number: WJM565FZQ3WP, Coursera</p>
        <a href="https://www.coursera.org/account/accomplishments/specialization/certificate/WJM565FZQ3WP" class="btn btn-primary" target="_blank">View Certificate</a>
       
      </div>
    </div>
  </div>


    <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Adobe Experience Manager (AEM) Architect</h5>
        <p class="card-text">AEM 6.x, Nov 2017, Adobe</p>
       
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Adobe Experience Manager (AEM) Sites Lead Developer</h5>
        <p class="card-text">AEM 6.x, Nov 2018, Adobe</p>
      
      </div>
    </div>
  </div>

  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Adobe Experience Manager (AEM) Sites Developer</h5>
        <p class="card-text">AEM 6.x, Aug 2017, Adobe</p>
       
      </div>
    </div>
  </div>

  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Adobe Experience Manager (AEM) Dev/Ops Engineer</h5>
        <p class="card-text">AEM 6.x, Aug 2017, Adobe</p>
       
      </div>
    </div>
  </div>

  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Adobe Experience Manager (AEM) Business Practitioner</h5>
        <p class="card-text">AEM 6.x, Jan 2018, Adobe</p>
       
      </div>
    </div>
  </div>

  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Certified Scrum Master</h5>
        <p class="card-text">Nov 2018, License number: 000843962, Scrum Alliance</p>
       
      </div>
    </div>
  </div>

  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">PRINCE2® Practitioner Certificate in Project Management</h5>
        <p class="card-text">Oct 2018, License number: GR657024698MS, AXELOS</p>
        <a href="https://www.peoplecert.org/for-corporations/certificate-verification-service" class="btn btn-primary" target="_blank">View Certificate</a>
      </div>
    </div>
  </div>

  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">PRINCE2® Foundation Certificate in Project Management</h5>
        <p class="card-text">Oct 2018, License number: GR656048251MS, AXELOS</p>
        <a href="https://www.peoplecert.org/for-corporations/certificate-verification-service" class="btn btn-primary" target="_blank">View Certificate</a>
       
      </div>
    </div>
  </div>


  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">ITIL® Foundation Certificate in IT Service Management</h5>
        <p class="card-text">Dec 2018, License number: GR750507121MS, AXELOS</p>
        <a href="https://www.peoplecert.org/for-corporations/certificate-verification-service" class="btn btn-primary" target="_blank">View Certificate</a>
       
      </div>
    </div>
  </div>

  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">C100DEV: MongoDB Certified Developer Associate Exam</h5>
        <p class="card-text">Feb 2017, License number: 817-614-322, MongoDB</p>
        <a href="https://university.mongodb.com/certification/certificate/817614322" class="btn btn-primary" target="_blank">View Certificate</a>
       
      </div>
    </div>
  </div>

  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">C100DBA: MongoDB Certified DBA Associate Exam</h5>
        <p class="card-text">May 2017, License number: 508-561-818, MongoDB</p>
        <a href="https://university.mongodb.com/certification/certificate/508561818" class="btn btn-primary" target="_blank">View Certificate</a>
       
      </div>
    </div>
  </div>

  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Introduction to Modern Application Development</h5>
        <p class="card-text">March 2017, License number: NPTEL17CS0626320211FN, Top 1%, NPTEL</p>
        <a href="http://nptel.ac.in/noc/E_Certificate/linkedin/noc17-cs06/NPTEL17CS0626320211FN.jpg" class="btn btn-primary" target="_blank">View Certificate</a>
       
      </div>
    </div>
  </div>


  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">MS: Programming in HTML5 with JavaScript and CSS3</h5>
        <p class="card-text">Jan 2013, License number: E126-1964, Microsoft</p>
        <a href="https://www.youracclaim.com/badges/0811a5f8-c205-4a91-8cec-2e47afc6aba2/linked_in_profile" class="btn btn-primary" target="_blank">View Certificate</a>
       
      </div>
    </div>
  </div>


          
        </div>

      
        <br/>

        <br />
        <br />
        <br />

        
     

      <br/>
      <br/>
      <br/>



        </div>
    </div>
  );
}

export default Certifications;
